import marked from 'marked'
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

marked.setOptions({
  gfm: true,
  breaks: true,
  sanitize: false,
  smartypants: true,
  xhtml: true,
})

const useStyles = makeStyles(() => ({
  root: {
    '& h1, & h2': {
      marginTop: '2em',
    },
  },
}))

const Markdown = ({ md, className = undefined }) => {
  const classes = useStyles()
  if (!md) {
    return null
  }
  return (
    <div
      dangerouslySetInnerHTML={{ __html: marked(md) }}
      className={clsx(classes.root, className)}
    />
  )
}

Markdown.propTypes = {
  md: PropTypes.string.isRequired,
}

export default React.memo(Markdown)
